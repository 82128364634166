export const DAYS_PER_MONTH = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

export const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const validateSubmit = (values, signUpSetting, locationCount, isNcrLoyaltyEnabled, isHappyWeddingAnniversaryAeActive) => {
  const { anniversaryDay, anniversaryMonth, birthDay, birthMonth, email, favoriteLocationId, name, phone, weddingDay, weddingMonth } = values;
  const { isSignUpNameEnabled, isSignUpNameRequired, isSignUpAnniversaryEnabled, isSignUpAnniversaryRequired, isSignUpBirthdayEnabled, isSignUpBirthdayRequired } = signUpSetting;

  return !email ||
    (!name && isSignUpNameEnabled && isSignUpNameRequired) ||
    (
      isHappyWeddingAnniversaryAeActive ?
        (
          (!weddingDay && isSignUpAnniversaryEnabled && (isSignUpAnniversaryRequired || weddingMonth)) ||
        (!weddingMonth && isSignUpAnniversaryEnabled && (isSignUpAnniversaryRequired || weddingDay))
        ) :
        (
          (!anniversaryDay && isSignUpAnniversaryEnabled && (isSignUpAnniversaryRequired || anniversaryMonth)) ||
        (!anniversaryMonth && isSignUpAnniversaryEnabled && (isSignUpAnniversaryRequired || anniversaryDay))
        )
    ) ||
    (!birthDay && isSignUpBirthdayEnabled && (isSignUpBirthdayRequired || birthMonth)) ||
    (!birthMonth && isSignUpBirthdayEnabled && (isSignUpBirthdayRequired || birthDay)) ||
    (!phone && isNcrLoyaltyEnabled) ||
    (!favoriteLocationId && locationCount > 1);
};

export const monthDayOptions = (t, values, type) => {
  // Month and Day options
  const monthOptions = [];
  const dayOptions = [];
  // If monthOptions array is empty add months from months obj
  if (monthOptions.length === 0) {
    Object.keys(MONTHS).forEach((key) => {
      monthOptions.push({ label: t(`models.user.birth_months.${MONTHS[key]}`), value: key });
    });
  }
  // If dayOptions array is empty add days based on month
  if (dayOptions.length === 0) {
    // If no month is selected, default to using 31 days
    if (values[`${type}Month`] === null) {
      [...Array(32).keys()].slice(1).forEach((num) => {
        dayOptions.push({ label: num, value: num });
      });
    } else {
      [...Array(DAYS_PER_MONTH[values[`${type}Month`]] + 1).keys()].slice(1).forEach((num) => {
        dayOptions.push({ label: num, value: num });
      });
    }
  }
  return { days: dayOptions, months: monthOptions };
};

export const validateMonthDate = (required, fieldName, basicFormApi) => {
  const { values, setMeta, setFieldMeta } = basicFormApi;
  const value = values[fieldName];

  let otherField = '';
  if (fieldName.includes('birth')) {
    otherField = fieldName === 'birthMonth' ? 'birthDay' : 'birthMonth';
  } else if (fieldName.includes('wedding')) {
    otherField = fieldName === 'weddingMonth' ? 'weddingDay' : 'weddingMonth';
  } else {
    // TODO: this should be removed once updated on the consumer profile from.
    otherField = fieldName === 'anniversaryMonth' ? 'anniversaryDay' : 'anniversaryMonth';
  }
  // If both BD fields have value OR if neither have value and BD fields are not required
  if ((value && values[otherField]) || (!value && !values[otherField] && !required)) {
    setMeta({ error: false }); // Set error message for current field to be blank
    setFieldMeta(otherField, { error: false }); // Set other BD field error message to be blank
    // Else if current field has value AND other BD field has no value
  } else if (value && !values[otherField]) {
    setFieldMeta(otherField, { error: 'cannot be blank' }); // Set error message on other BD field
  }
};

export const ACCESS_ACTION_LIMIT = 5;
